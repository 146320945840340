import React, { useEffect } from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import wave from '../assets/icons/wave.svg';

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = ({ toggle }) => {
  const [test, setTest] = useState(false);
  const [show, setShow] = useState('none');

  useEffect(() => {
    setShow(test ? 'block' : 'none');
  }, [test]);

  const change = () => {
    setTest(prevtest => !prevtest);
    // setShow(test ? 'block' : 'none');
  }
  return (
  <button onClick={() => {toggle(); change() }}>
    <motion.img key={test} src={wave} alt="wave" className='absolute rounded-full cursor-pointer' width={'70px'}
          style={{ cursor: 'pointer', display: test ? 'none' : 'block'}}
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: '70px',
          transitionEnd: {
            display: test ? 'none' : 'block'
          }
        }}
        transition={{ duration: 1 }}
    />
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        display={show}
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" }
        }}
        
      />
      <Path

        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 }
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        display={show}
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" }
        }}
      />
    </svg>
  </button>
)};