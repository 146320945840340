import * as React from "react";
import { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensons.js"
import { MenuToggle } from "./MenuToggle.jsx";
// import { MenuToggle } from "./MenuToggle";
// import { Navigation } from "./Navigation";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    height: '350px',
    width: '350px',
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(30px at 32px 32px)",
    height: '70px',
    width: '70px',
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

const variants = {
  open: {
    display: 'block',
    transition: { delay: 0.5, staggerChildren: 0.07, delayChildren: 1.9 }
  },
  closed: {
    display: 'none',
    transition: { delay: 0.9, staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const variant = {
  open: {
    y: 0,
    display: 'flex',
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    },
  },
  closed: {
    y: 20,
    display: 'none',
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const nav = {
  open: {
    height: '350px',
    width: '350px',
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    },
  },
  closed: {
    height: '70px',
    width: '70px',
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

export const Example = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  console.log(isOpen)

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
      variants={nav}
    >
      <motion.div className="background" variants={sidebar} />
      {/* <Navigation /> */}
      <motion.form style={{ position: 'absolute', width: '90%' }} variants={variants} onSubmit={() => {}}>
        <motion.p className="mb-2 justify-center" variants={variant}>Drop A Message</motion.p>
        <motion.div className="w-full mb-[10px]" variants={variant}>
          <input className="w-full bg-slate-400 rounded p-[5px] text-white outline-none shadow-[0_0_25px_rgba(148,163,184,0.2)]" type="email" placeholder="email" />
        </motion.div>
        <motion.div className="w-full mb-[10px]" variants={variant}>
          <input className="w-full bg-slate-400 rounded p-[5px] text-white outline-none shadow-[0_0_25px_rgba(148,163,184,0.2)]" type="name" placeholder="name" />
        </motion.div>
        <motion.div className="w-full mb-[5px]" variants={variant}>
          <motion.textarea rows={5} className="w-full bg-slate-400 text-white rounded p-[5px] outline-none shadow-[0_0_25px_rgba(148,163,184,0.2)]" name="message" placeholder="message" />
        </motion.div>
        <motion.div id="messageBtn" className="flex w-full justify-center align-middle" variants={variant}>
          <button type="submit" className="bg-black rounded-md w-auto text-white hover:bg-zinc-800">Submit</button>
        </motion.div>
      </motion.form>
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.nav>
  );
};