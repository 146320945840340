import React from 'react';
import { motion } from 'framer-motion';


import tempVideo from '../assets/comingSoon.mp4';
import logo from '../assets/logo-b&w.png';

const ComingSoon = () => {
  return (
    <div className='flex justify-start items-center flex-col h-screen'>
      <div className='relative w-full h-screen'>
        <img 
          className='absolute -m-5 cursor-pointer z-10'
          src={logo} alt="logo"
          width={'150px'}
        />
        <video 
          src={tempVideo}
          type="video/mp4"
          loop
          controls={false}
          muted
          autoPlay
          className='w-full h-screen object-cover'
        />
        <div className='absolute flex flex-col justify-center items-center top-0 bottom-0 right-0 left-0'>
          <motion.h1 className='text-center font-semibold'
            animate={{
              x: [-1000, 0, 1500]
            }}
            transition={{
              duration: 20,
              // ease: 'ease',
              times: [0, 0.8, 1],
              repeat: Infinity,
              repeatDelay: 0.1
            }}
          >Coming Soon...</motion.h1>
          <h2 className='text-center text-[var(--gold-font)] font-semibold'>Please check back 😇</h2>
        </div>
      </div>
    </div>
  )
}

export default ComingSoon;