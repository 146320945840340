import React from 'react'
import ComingSoon from './components/ComingSoon';
import { Example } from './components/Example';

const App = () => {
  return (
    <div className='relative'>
      <ComingSoon />
      <Example />
    </div>
  )
}

export default App;